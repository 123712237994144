import React from "react";
import "./styles/index.scss";

function App() {
  return (
    <div className="bg-gray-900 p-20 h-screen flex justify-center items-start flex-col">
      <img alt="loop-logo" src="https://www.theloop.pt/assets/images/loop-white.png" className="h-36 object-contain" />
      <div className="ml-3 mt-8">
        <h1 className="text-5xl text-white">ReactJS Boilerplate 👋</h1>
        <p className="text-gray-400 mt-5 text-lg">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit consequuntur odio aut nobis ab quis? Reiciendis
          doloremque ut quo fugiat eveniet tempora, atque alias earum ullam inventore itaque sapiente iste?
        </p>
        <button type="button" className="button button--primary hover:opacity-80 mt-5" onClick={() => {}}>
          Let&#39;s go! 🚀
        </button>
      </div>
    </div>
  );
}

export default App;
